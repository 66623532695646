import { ErrorService } from "./../services/error.service";
import { validateHorizontalPosition } from "@angular/cdk/overlay";
import { HttpClient } from "@angular/common/http";
import { visitAll } from "@angular/compiler";
import { Injectable } from "@angular/core";
import { id } from "@swimlane/ngx-charts";
import { resolve } from "dns";
import { Config, element, promise } from "protractor";
import { AdminSettingService } from "../components/myAccount/services/admin-setting-service";
import { SearchData, VisualList } from "../models/admin";

import { mediaLib, visual, mediaFile, clip } from "../models/media";
import { MediaService } from "../services/dataServices/media.service";
import { LoaderService } from "../services/loader.service";
import { cSessionService } from "../services/session.service";
import { ApiURL } from "../shared/conf/api-path";
import { ConfigManagerService } from "./config-manager.service";
import { environment } from "src/environments/environment";
import { SuccessMessageModalService } from "../services/success-message-modal.service";

@Injectable({
  providedIn: "root",
})
export class MediaManagerService {
  visualsData: VisualList;
  public replaceMediaTypeFromVisualPopup : any;
  constructor(
    public dataservice: MediaService,
    public loader: LoaderService,
    public config: ConfigManagerService,
    public sessionService: cSessionService,
    public adminSettingService: AdminSettingService,
    private http: HttpClient,
    private errorMessage: ErrorService,
    private successModal : SuccessMessageModalService
  ) {}
  public selectedmedialib: mediaLib;
  public mediaLibs: mediaLib[] = [];

  public uploadMediaList: mediaFile[] = [];
  public visuals: visual[] = [];
  public selectedVisual: visual;
  public selectedVisuals: visual[] = [];
  public visualsCount = 0;
  public visualsTotalCount = 0;
  public selectedPage = "";

  async getMediaLibraries(email, session_id, mediaLibStatus): Promise<unknown> {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataservice
        .getMediaLibrary(email, session_id, mediaLibStatus)
        .toPromise()
        .then((res) => {
          this.loader.hideLoader();
          this.mediaLibs = res["media_libs"] as mediaLib[];

          resolve(this.mediaLibs);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  addMediaLib(
    email,
    session_id,
    lib_name,
    keyterms,
    description,
    category
  ): Promise<unknown> {
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataservice
        .addMediaLibrary(
          email,
          session_id,
          lib_name,
          keyterms,
          description,
          category
        )
        .toPromise()
        .then((res) => {
          //  console.log("res", res);
          let media = new mediaLib();
          media.name = lib_name;
          media.total_items = 0;
          media.activation_status = "active";
          this.mediaLibs.push(media);
          resolve(this.mediaLibs);
          this.loader.hideLoader();
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  getMediaLibrary(_id: string): Promise<any> {
    const __this = this;

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.API_HOST_ADMIN + ApiURL.admin.media.get, { _id })
        .toPromise()
        .then((response) => {
          __this.selectedmedialib = response;
          this.selectedmedialib = response;
          resolve("true");
        });
    });
  }
  updateMediaLibrary(
    mediaLibName,
    libActivationStatus,
    sUserEmail,
    sSessionId
  ) {
    this.loader.showLoader();
    //  console.log("Service",media);
    let mediaLibStatus = "all";
    this.dataservice
      .updateMediaLibrary(
        mediaLibName,
        libActivationStatus,
        sUserEmail,
        sSessionId
      )
      .toPromise()
      .then((res) => {
        this.dataservice
          .getMediaLibrary(sUserEmail, sSessionId, mediaLibStatus)
          .toPromise()
          .then((res) => {
            this.loader.hideLoader();
            this.mediaLibs = res["media_libs"] as mediaLib[];
          })
          .catch((err) => {});
        this.loader.hideLoader();
      });
  }

  getAdminMediaList(search: SearchData): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const visualType = search.visual_type;
      this.visualsData = await this.dataservice.getAdminMediaList(search);
      const res = this.visualsData;
      // console.log("all", res);

      if (res.visuals &&  res.visuals.items) {
        this.visuals = res.visuals.items;

        this.visualsCount = res.visuals.audios_count;
        this.visualsTotalCount = res["total_count"];
        let clipCount = res.visuals.clips_count;
        let imageCount = res.visuals.images_count;

        if (clipCount > this.visualsCount) {
          this.visualsCount = clipCount;
        }

        if (imageCount > this.visualsCount) {
          this.visualsCount = imageCount;
        }
      } else {
        this.visuals = res["visuals"] as visual[];
        this.visualsCount = res["count"];
      }

      // this.getsignedThumbandURL(
      //   this.sessionService.cSessionService_GetUserEmail(),
      //   this.sessionService.SessionService_GetSessionId()
      // );
      this.loader.hideLoader();
      resolve("");
    });
  }

  getVisuals(
    email,
    session_id,
    visualType,
    medilibId,
    num_items,
    skip_video,
    search_keyword
  ): Promise<unknown> {
    this.loader.showLoader();
    this.visuals = [];

    let promise = new Promise((resolve, reject) => {
      this.dataservice
        .getvisual(
          email,
          session_id,
          visualType,
          medilibId,
          num_items,
          skip_video,
          search_keyword
        )
        .toPromise()
        .then((res) => {
          this.visuals = [];
          if (visualType == "all") {
            res["visuals"]["audios"]["items"].forEach((element: visual) => {
              element.visual_type = "audio";
              this.visuals.push(element);
            });

            res["visuals"]["clips"]["items"].forEach((element: visual) => {
              element.visual_type = "clip";
              this.visuals.push(element);
            });

            res["visuals"]["images"]["items"].forEach((element: visual) => {
              element.visual_type = "image";
              this.visuals.push(element);
            });
            // TODO media loading..
            // console.log("All Visual response", res);
            //this.visuals = this.visuals.concat(...(res['visuals']['audios']['items'].map(c =>c.visual_type ='audio')  as visual[]));
            this.visualsCount = res["visuals"]["audios"]["count"] as number;
            // this.visuals = this.visuals.concat(...(res['visuals']['clips']['items'].map(c =>  c.visual_type ='clip')  as visual[]));
            // this.visuals = this.visuals.concat(...(res['visuals']['images']['items'].map(c =>c.visual_type ='image') as visual[]));

            // TODO media loading
            // console.log("All visuals", this.visuals);

            let clipCount = res["visuals"]["clips"]["count"];
            let imageCount = res["visuals"]["images"]["count"];

            if (clipCount > this.visualsCount) {
              this.visualsCount = clipCount;
            }

            if (imageCount > this.visualsCount) {
              this.visualsCount = imageCount;
            }
          } else {
            this.visuals = res["visuals"] as visual[];
            this.visualsCount = res["count"];
          }

          this.getsignedThumbandURL(email, session_id);
          this.loader.hideLoader();

          resolve("");
        })
        .catch((err) => {
          this.loader.hideLoader();
        });
    });

    return promise;
  }

  public replacementClips: visual[] = [];
  getReplaceClip(email, session_id, visualType, replace_id): Promise<unknown> {
    this.loader.showLoader();
    this.replacementClips = [];
    this.visualsCount = 0;
    let promise = new Promise((resolve, reject) => {
      this.dataservice
        .getReplaceClips(email, session_id, visualType, replace_id)
        .then((res) => {
          this.loader.hideLoader();
          (res["new_clips"] as string[]).forEach((ele) => {
            let cl = new visual();
            cl.visual_type = "clip";
            cl.visual_path = ele;
            let thumb =
              cl.visual_path.slice(0, 15) +
              "thumbs/" +
              cl.visual_path.slice(15, cl.visual_path.length);
            thumb = cl.visual_path
              .replace(".mp4", "_thumb.jpg")
              .replace(".mov", "_thumb.jpg")
              .replace(".avi", "_thumb.jpg");
            cl.thumb = thumb;
            this.replacementClips.push(cl);
          });

          this.getsignedURLForReplacmentclip(email, session_id);
          resolve(res);
        })
        .catch((err) => {
          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  public audios: visual[] = [];
  getAudios(email, session_id, num_Items, num_Skips) {
    this.audios = [];
    this.loader.showLoader();
    let promise = new Promise((resolve, reject) => {
      this.dataservice
        .getPronotoaudio(email, session_id, num_Items, num_Skips)
        .then((res) => {
          this.loader.hideLoader();
          // console.log(res["audio_files"]);
          (res["audio_files"] as string[]).forEach((ele) => {
            let v = new visual();
            v.visual_type = "audio";
            v.visual_path = ele;
            v.isSelected = false;
            v.name = ele.slice(ele.indexOf(".com/") + 5, ele.length);

            /* this.dataservice.getduration(email,session_id,v.visual_path).then(r=>{
             console.log(r);
             v.duration =r['duration'];
           }) */
            this.audios.push(v);
          });

          this.getsignedURLForaudioclip(email, session_id);

          resolve(res[""]);
        })
        .catch((err) => {
          this.loader.hideLoader();
          reject(err);
        });
    });

    return promise;
  }

  getVisualCount(email, session_id, visualType, medilibId): Promise<unknown> {
    this.loader.showLoader();
    this.visualsCount = 0;
    let promise = new Promise((resolve, reject) => {
      this.dataservice
        .getVisualListByMediaLib(
          email,
          session_id,
          visualType,
          medilibId,
          18,
          0
        )
        .toPromise()
        .then((res) => {
          this.visualsCount = res["count"];

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  }

  getMediaThumbandURL(email, session_id, fPath) {
    return new Promise((resolve, reject) => {
      this.dataservice
        .getBatchSignedURL(email, session_id, fPath)
        .then((res) => {
          console.log("Thumb nail");
          resolve(res);
        })
        .catch((err) => {
          reject();
        });
    });
  }

  getsignedThumbandURL(email, session_id) {
    let batchUrl: string[] = [];
    this.visuals.forEach((vid) => {
      vid.loadingUrl = true;
      vid.loadingThumbnail = true;
      if (vid.visual_path != "") batchUrl.push(vid.visual_path);

      if (vid.visual_type == "clip") {
        if (vid.thumb != "") batchUrl.push(vid.thumb);
      }
    });

    this.dataservice
      .getBatchSignedURL(email, session_id, batchUrl)
      .then((res) => {
        let output = res["output"];
        // console.log("output2", output);

        // console.log("visual count", this.visuals.length);
        this.visuals.forEach((e, index) => {
          e.loadingUrl = false;
          e.loadingThumbnail = false;
          // console.log("element", e);
          e.url = output.filter((a) => a[e.visual_path])[0][e.visual_path];
          if (e.thumb && e.visual_type == "clip") {
            e.thumbnail = output.filter((a) => a[e.thumb])[0][e.thumb];
          }
          // console.log("element", e);
          if (e.visual_type == "image") {
            e.thumbnail = output.filter((a) => a[e.visual_path])[0][
              e.visual_path
            ];
          }

          // console.log(e);
        });
        //  console.log("singed-------------------" + JSON.stringify(vid));
      })
      .catch((err) => {
        this.visuals.forEach((e, index) => {
          e.loadingUrl = false;
          e.loadingThumbnail = false;
        });
      });
  }

  getsignedURLForReplacmentclip(email, session_id) {
    let batchUrl: string[] = [];
    this.replacementClips.forEach((vid) => {
      vid.loadingUrl = true;
      if (vid.visual_path != "") batchUrl.push(vid.visual_path);

      if (vid.thumb != "") vid.loadingThumbnail = true;
      batchUrl.push(vid.thumb);
    });

    this.dataservice
      .getBatchSignedURL(email, session_id, batchUrl)
      .then((res) => {
        let output = res["output"];
        console.log("output", output);
        this.replacementClips.forEach((e, index) => {
          e.url = output.filter((a) => a[e.visual_path])[0][e.visual_path];
          e.thumbnail = output.filter((a) => a[e.thumb])[0][e.thumb];
          console.log(e);
          setTimeout(() => {
            e.loadingUrl = false;
            e.loadingThumbnail = false;
          }, 1000);
        });
        //  console.log("singed-------------------" + JSON.stringify(vid));
      })
      .catch((err) => {
        this.replacementClips.forEach((e, index) => {
          e.loadingUrl = false;
        });
      });
  }

  getsignedURLForaudioclip(email, session_id) {
    let batchUrl: string[] = [];
    this.audios.forEach((vid) => {
      vid.loadingUrl = true;
      if (vid.visual_path != "") batchUrl.push(vid.visual_path);
    });

    this.dataservice
      .getBatchSignedURL(email, session_id, batchUrl)
      .then((res) => {
        let output = res["output"];
        console.log("output", output);
        this.audios.forEach((e, index) => {
          e.loadingUrl = false;

          e.url = output.filter((a) => a[e.visual_path])[0][e.visual_path];

          console.log(e);
        });
        //  console.log("singed-------------------" + JSON.stringify(vid));
      })
      .catch((err) => {
        this.audios.forEach((e, index) => {
          e.loadingUrl = false;
        });
      });
  }

  deleteVisual(email, session_id, visual_id): Promise<unknown> {
    this.loader.showLoader();

    let promise = new Promise((resolve, reject) => {
      this.dataservice
        .deleteVisual(email, session_id, visual_id)
        .toPromise()
        .then((res) => {
          this.loader.hideLoader();
          this.visuals.forEach((element, index) => {
            if (element.visual_path == this.selectedVisual.visual_path) {
              this.visuals.splice(index, 1);
            }
          });
          resolve(res);
        })
        .catch((err) => {
          reject("");
          this.loader.hideLoader();
        });
    });
    return promise;
  }

  deleteAllSelectedVisuals(email, session_id) {
    let promises: Promise<Object>[] = [];
    this.loader.showLoader();
    this.selectedVisuals.forEach((ele) => {
      promises.push(
        this.dataservice
          .deleteVisual(email, session_id, ele.visual_path)
          .toPromise()
      );
    });

    Promise.all(promises)
      .then((ele) => {
        this.loader.hideLoader();

        this.selectedVisuals.forEach((selec) => {
          this.visuals.forEach((visu: visual, index) => {
            if (selec.visual_path === visu.visual_path) {
              this.visuals.splice(index, 1);
            }
          });
        });

        this.selectedVisuals = [];
      })
      .catch((err) => {
        this.loader.hideLoader();
      });
  }

  // updateVisual(email, session_id, visual_id, keyterms) {
  //   this.loader.showLoader();
  //   this.dataservice
  //     .updateVisual(email, session_id, visual_id, keyterms)
  //     .toPromise()
  //     .then((res) => {
  //       this.loader.hideLoader();
  //     })
  //     .catch((err) => {
  //       this.loader.hideLoader();
  //     });
  // }

  // uploadMedia(email, session_id) {
  //   console.log(this.selectedmedialib);
  //   console.log(this.selectedmedialib._id.$oid);
  //   this.uploadMediaList.forEach((ele: mediaFile) => {
  //     console.log("file field", ele);
  //     if (!ele.status) {
  //       ele.status = "uploading";
  //       console.log(this.selectedmedialib);
  //       this.dataservice
  //         .DataService_BulkUpload(
  //           email,
  //           session_id,
  //           this.selectedmedialib._id.$oid,
  //           ele.file.name,
  //           ele.file.type
  //         )
  //         .then((e) => {
  //           var signed_url = e["signed_url"];

  //           this.dataservice
  //             .DataService_UploadFileOnSignedUrl(ele.file, signed_url)
  //             .then((es) => {
  //               this.dataservice
  //                 .AddUserMedia(
  //                   email,
  //                   session_id,
  //                   ele.file.name,
  //                   this.selectedmedialib._id.$oid,
  //                   ele.file.size
  //                 )
  //                 .then((res) => {
  //                   ele.status = "uploaded";

  //                   this.dataservice
  //                     .getVisual(email, session_id, res["fpath"])
  //                     .then((vis) => {
  //                       let visual = vis as visual;
  //                       // visual.thumb = res['thumb']
  //                       // visual.url = res['url'];
  //                       visual.loadingUrl = true;
  //                       this.dataservice
  //                         .getSignedURL(email, session_id, visual.visual_path)
  //                         .then((resp) => {
  //                           console.log("resp", resp);

  //                           visual.url = resp["url"];
  //                           visual.loadingUrl = false;
  //                         })
  //                         .catch((err) => {
  //                           visual.loadingUrl = false;
  //                         });
  //                       console.log("vis", visual);
  //                       if (visual.visual_type == "clip") {
  //                         visual.loadingThumbnail = true;
  //                         this.dataservice
  //                           .getThumbBatchSignedURL(email, session_id, [
  //                             visual.visual_path,
  //                           ])
  //                           .then((resp) => {
  //                             console.log(resp[0]);
  //                             visual.thumbnail =
  //                               resp["output"][0].thumb_signed_url;
  //                             visual.loadingThumbnail = false;

  //                             console.log("visual_thumbnail", visual);
  //                           })
  //                           .catch((err) => {
  //                             visual.loadingThumbnail = false;
  //                           });
  //                       }

  //                       this.visuals.push(visual);

  //                       console.log("visual", visual);
  //                     });
  //                 });
  //               console.log(es);
  //             });
  //         });
  //     }
  //   });
  // }

  // USING THIS METHOD TO UPLOAD FROM ADMI
  uploadFileToBucket(
    email,
    session_id,
    ele,
    mediaLibId,
    brandId = undefined,
    ownerType = "user",
    is_font = undefined,
  ) {
    if (!ele.status) {
      ele.status = "uploading";
      // const currentTS = Math.floor(Date.now() / 1000) + "-";
      this.dataservice
        .DataService_BulkUpload(
          email,
          session_id,
          mediaLibId,
          ele.file.name,
          ele.file.type,
          brandId,
          ownerType,
          undefined,
          is_font
        )
        .then((e: any) => {
          console.log("e bulk upload start...", e);
          const signed_url = e["signed_url"];
          var fpath = e.fpath;
          this.dataservice
            .DataService_UploadFileOnSignedUrl(ele.file, signed_url)
            .then(async (es: any) => {
              await this.dataservice
                .AddUserMedia(
                  email,
                  session_id,
                  ele.name,
                  mediaLibId,
                  ele.file.size,
                  brandId,
                  fpath
                )
                .then(async (res: any) => {
                  ele.status = "uploaded";
                  ele.status = "uploaded";

                  this.dataservice
                    .getAdminMediaVisual(email, session_id, fpath)
                    .then((vis) => {
                      let visual = vis as visual;
                      visual.thumb = res["thumb"];
                      visual.url = res["url"];
                      visual.loadingUrl = true;
                      this.dataservice
                        .getSignedURL(email, session_id, visual.visual_path)
                        .then((resp) => {
                          visual.url = resp["url"];
                          visual.thumbnail_signed_url = resp["url"];
                          visual.loadingUrl = false;
                        })
                        .catch((err) => {
                          visual.loadingUrl = false;
                        });

                      if (visual.visual_type == "clip") {
                        visual.loadingThumbnail = true;
                        this.dataservice
                          .getThumbBatchSignedURL(email, session_id, [
                            visual.visual_path,
                          ])
                          .then((resp) => {
                            visual.thumbnail = resp["output"][0].thumb_signed_url;
                            visual.thumbnail_signed_url = resp["output"][0].thumb_signed_url;
                            visual.loadingThumbnail = false;
                          })
                          .catch((err) => {
                            visual.loadingThumbnail = false;
                          });
                      }

                      this.visuals.push(visual);
                    })
                    .catch((err) => {
                      ele.status = "failed";
                      this.errorMessage.handleError(
                        err.error ? err.error : err,
                        "Media Upload",
                        true,
                        true
                      );
                      console.log("Get vidual Error in call ...");
                    });
                })
                .catch((err) => {
                  ele.status = "failed";
                  this.errorMessage.handleError(
                    err.error ? err.error : err,
                    "Media Upload",
                    true,
                    true
                  );
                  console.log("Get vidual Error in call ...");
                });
            }).catch((err) => {
              ele.status = "failed";
              this.errorMessage.handleError(
                err.error ? err.error : err,
                "Media Upload",
                true,
                true
              );
              console.log("Get vidual Error in call ...");
            });
        }).catch((err) => {
          ele.status = "failed";
          this.errorMessage.handleError(
            err.error ? err.error : err,
            "Media Upload",
            true,
            true
          );
          console.log("Get vidual Error in call ...");
        });
    }
  }
  async uploadUserAvatar(
    email: string,
    session_id: string,
    mediaLibId: string,
    ele: mediaFile,
    brandId = undefined,
    isFont = undefined,
    isSecret = undefined,
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        if (!ele.status) {
          // ele.status = "uploading";

          let vs = new visual();
          vs.loadingUrl = true;
          vs.loadingThumbnail = true;
          // ele.status = 'uploading';
          if (mediaLibId && mediaLibId != undefined) {
            ele.status = "uploading";
            // const currentTS = Math.floor(Date.now() / 1000) + "-";
            const dataUpload: any =
              await this.dataservice.DataService_BulkUpload(
                email,
                session_id,
                mediaLibId,
                ele.file.name,
                ele.file.type,
                brandId,
                "user",
                isFont,
                isSecret
              );
            if (dataUpload) {
              var signed_url = dataUpload["signed_url"];
              var fpath = dataUpload["fpath"];

              await this.dataservice
                .DataService_UploadFileOnSignedUrl(ele.file, signed_url)
                .then(async (es) => {

                  let fpathFileName = ele.name;
                  if(isSecret){
                    let fileN = fpath.split("/");
                    fpathFileName = fileN[fileN.length - 1];

                  }
                  await this.dataservice
                    .AddUserMedia(
                      email,
                      session_id,
                      fpathFileName,
                      mediaLibId,
                      ele.file.size,
                      brandId,
                      fpath
                    )
                    .then(async (res) => {
                      ele.status = "uploaded";

                      await this.dataservice
                        .getAdminMediaVisual(email, session_id, fpath)
                        .then(async (vis) => {
                          vs = vis as visual;
                          // visual.thumb = res['thumb']
                          // visual.url = res['url'];
                          vs.name = ele.name;
                          vs.loadingUrl = true;
                          vs.loadingThumbnail = true;
                          await this.dataservice
                            .getSignedURL(email, session_id, vs.visual_path)
                            .then(async (resp) => {
                              // console.log(res);
                              return resolve(res);
                            });
                        });
                    });
                }).catch((err) => {
                  this.successModal.close();
                  ele.status = "failed";
                      this.errorMessage.handleError(
                        err.error ? err.error : err,
                        "Media Upload",
                        true,
                        true
                      );
                });
            }
            // this.uploadFileToBucket(profileEmail, session_id, ele, mediaLibId).then( (res) => {

            // };
          } else {
            // console.log(" Media library is not selected.");
          }
        }
      } catch (err) {
        this.successModal.close();
        ele.status = 'failed';
        this.errorMessage.handleError(err, "Media Upload", true, true);
        // console.log("Get vidual Error in call ...");
      }
    });
  }

  async uploadAdminMedia(
    email: string,
    session_id: string,
    mediaLibId: string,
    profileEmail: string,
    brandId = undefined,
    ownerType = "user",
    is_secret = false
  ): Promise<visual> {
    return new Promise<visual>(async (resolve, reject) => {
      this.uploadMediaList.forEach((ele: mediaFile) => {
        if (!ele.status) {
          // ele.status = "uploading";

          let vs = new visual();
          vs.loadingUrl = true;
          vs.loadingThumbnail = true;
          // ele.status = 'uploading';
          if (mediaLibId && mediaLibId != undefined) {
            this.uploadFileToBucket(
              profileEmail,
              session_id,
              ele,
              mediaLibId,
              brandId,
              ownerType,
              is_secret
            );
          } else {
            // console.log(" Media library is not selected.");
          }
        }
      });
    });
  }

  // Media upload
  async uploadMediaFilesAdmin(
    email,
    session_id,
    media: mediaFile,
    uploadToMediaLibrary = undefined,
    brandId = undefined
  ): Promise<visual> {
    let uploadToLibrary = this.mediaLibs;
    let uploadToLibraryName = "";
    if(uploadToMediaLibrary){
      uploadToLibrary = uploadToMediaLibrary;
    }
    // console.log(" upload media for Admin ...");
    if (!uploadToLibrary && this.mediaLibs.length == 0) {
      await this.getMediaLibraries(email, session_id, "active").then();
      uploadToLibrary = this.mediaLibs;
      uploadToLibraryName = this.mediaLibs[0].name;
    } else {
      uploadToLibraryName = uploadToMediaLibrary;
    }

    let promise = new Promise<visual>(async (resolve, reject) => {
      let vs = new visual();

      media.status = "uploading";
      // const currentTS = Math.floor(Date.now() / 1000) + "-";
      await this.dataservice
        .DataService_BulkUpload(
          email,
          session_id,
          uploadToLibrary,
          media.file.name,
          media.file.type
        )
        .then(async (e : any) => {
          console.log("   Siged url resp", e);

          let signed_url = e["signed_url"];
         
          let fPath = e.fpath;
          console.log(fPath , "fPath url", e.fpath);
          await this.dataservice
            .DataService_UploadFileOnSignedUrl(media.file, signed_url)
            .then(async (es) => {
              await this.dataservice
                .AddUserMedia(
                  email,
                  session_id,
                  media.file.name,
                  uploadToLibraryName,
                  media.file.size,
                  brandId,
                  fPath
                )
                .then(async (res) => {
                  media.status = "uploaded";

                  await this.dataservice
                    .getAdminMediaVisual(email, session_id, res["fpath"])
                    .then(async (vis) => {
                      vs = vis as visual;
                      // visual.thumb = res['thumb']
                      // visual.url = res['url'];
                      vs.name = media.name;
                      vs.loadingUrl = true;
                      vs.loadingThumbnail = true;
                      await this.dataservice
                        .getSignedURL(email, session_id, vs.visual_path)
                        .then(async (resp) => {
                          vs.url = resp["url"];
                          vs.loadingUrl = false;
                        })
                        .catch((err) => {
                          vs.loadingUrl = false;
                        });

                      if (vs.visual_type == "clip") {
                        await this.dataservice
                          .getThumbBatchSignedURL(email, session_id, [
                            vs.visual_path,
                          ])
                          .then(async (resp) => {
                            vs.thumbnail = resp["output"][0].thumb_signed_url;
                            vs.loadingThumbnail = false;
                          })
                          .catch((err) => {
                            vs.loadingThumbnail = false;
                          });
                      }
                    });
                })
                .catch((err) => {
                  throw err;
                });
            })
            .catch((err) => {
              throw err;
            });

          resolve(vs);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  updateVisual(type: any, visual_id: any, key_terms: any) {
    const __this = this;

    // console.log("tagdata");

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.API_HOST_ADMIN + ApiURL.admin.visual.updateVisual, {
          type,
          visual_id,
          key_terms,
        })
        .toPromise()
        .then((response) => {
          // __this.selectedmedialib = response;
          // this.selectedmedialib = response;
          resolve("true");
        });
    });
  }
}
function reject(arg0: string) {
  throw new Error("Function not implemented.");
}
